import * as R from 'ramda';

import getIsEnterprise from './getIsEnterprise';

export type VCSType = 'bitbucket' | 'github' | 'circleci';

export interface Identity {
  login: string;
  type: string;
  avatarUrl?: string;
}

interface APIEmailAuthentication {
  login: string;
  email_verified: boolean;
}

interface EmailAuthentication {
  login: string;
  isEmailVerified: boolean;
}

interface APIIdentity {
  login: string;
  type: string;
  avatar_url?: string;
}

export interface APIIdentities {
  [type: string]: APIIdentity;
}
export interface APIMe {
  analytics_id: string;
  avatar_url: string;
  name: string;
  login: string;
  selected_email: string | null;
  identities: APIIdentities;
  first_vcs_authorized_client_id: string | null;
  privacy_optout: boolean;
  projects:
    | {
        [projectUrl: string]: {
          on_dashboard: boolean;
          emails: string;
        };
      }
    | Record<string, unknown>;
  web_ui_pipelines_first_opt_in: boolean;
  web_ui_pipelines_optout: string | null;
  num_projects_followed: number;
  sign_in_count: number;
  github_oauth_scopes: string[];
  created_at: string;
  in_beta_program: boolean | string;
  admin: string | null;
  enrolled_betas: Array<any>;
  basic_email_prefs: string;
  pusher_id: string;
  email_authentication: APIEmailAuthentication | null;
}

export interface Project {
  vcsType: VCSType;
  username: string;
  project: string;
}

export interface Me {
  avatarUrl: string | null;
  id: string;
  name: string;
  selectedEmail: string | null;
  identities: Identity[];
  firstVcsAuthorizedClientId: string | null;
  privacyOptout: boolean;
  followedProjects: Project[];
  webUIPipelinesFirstOptIn: boolean;
  webUIPipelinesOptOut: string | null;
  numberOfProjectsFollowed: number;
  signInCount: number;
  githubOauthScopes: Array<string | null>;
  createdAt: string;
  admin: string | null;
  pusherId: string;
  inBetaProgram: boolean;
  emailAuthentication: EmailAuthentication | null;
}

const vcsTypes = {
  'github.com': 'github' as VCSType,
  'bitbucket.org': 'bitbucket' as VCSType,
  'circleci.com': 'circleci' as VCSType,
};

type Keys = 'github.com' | 'bitbucket.org' | 'circleci.com';

const followedProjects = R.pipe(
  R.filter(R.propEq('on_dashboard', true as any)),
  R.keys as any,
  R.map((projectURL: string) => {
    const splitProjectURL: Keys[] = R.takeLast(
      3,
      R.split('/', projectURL),
    ) as Keys[];
    const [vcsHost, username, project] = splitProjectURL;
    //Deriving this from a string only works on GH Cloud and BB Cloud.
    //Installations of GHE or Bitbucket server will have a custom URL scheme
    //without these indicators in the domain.
    //We don't have great options here but since GH is only supported on
    //CircleCI Enterprise we are going to assume its VCSType is github.
    //Long term, we will need this to be returned in the data from /me.
    const vcsType = getIsEnterprise()
      ? vcsTypes['github.com']
      : vcsTypes[vcsHost];

    return { vcsType, username, project };
  }),
);

const mapIdentities = (identities: APIIdentities): Identity[] => {
  return Object.values(identities).map(({ type, login, avatar_url }) => {
    return {
      type,
      login,
      avatarUrl: avatar_url,
    };
  });
};

export default ({
  avatar_url,
  analytics_id,
  name,
  selected_email,
  identities,
  first_vcs_authorized_client_id,
  privacy_optout,
  web_ui_pipelines_first_opt_in,
  web_ui_pipelines_optout,
  projects,
  num_projects_followed,
  sign_in_count,
  github_oauth_scopes,
  created_at,
  admin,
  pusher_id,
  in_beta_program,
  login,
  email_authentication,
}: APIMe): Me => {
  return {
    avatarUrl: avatar_url,
    id: analytics_id,
    name: name || login,
    selectedEmail: selected_email,
    identities: mapIdentities(identities),
    firstVcsAuthorizedClientId: first_vcs_authorized_client_id,
    privacyOptout: !!privacy_optout,
    webUIPipelinesFirstOptIn: !!web_ui_pipelines_first_opt_in,
    webUIPipelinesOptOut: web_ui_pipelines_optout,
    followedProjects: followedProjects(projects as any) || [],
    numberOfProjectsFollowed: num_projects_followed,
    signInCount: sign_in_count,
    githubOauthScopes: github_oauth_scopes,
    createdAt: created_at,
    admin: admin,
    emailAuthentication: email_authentication
      ? {
          login: email_authentication.login,
          isEmailVerified: email_authentication.email_verified,
        }
      : null,
    pusherId: pusher_id,
    //getting rollbars where API returns this as a string sometimes.
    inBetaProgram:
      typeof in_beta_program === 'string'
        ? in_beta_program.toLowerCase() === 'true'
        : !!in_beta_program,
  };
};
