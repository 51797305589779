import * as React from 'react';

import NextError from 'next/error';

import ExceptionLogger from '~/backplane/appExceptionLogger';
import { Error404 } from '~/components/Error404';

interface WebUiError extends Error {
  message: string;
}

interface Props {
  error: WebUiError;
  statusCode?: number;
}

const defaultErrorCode = 400;

const Error = ({ statusCode, error }: Props) => {
  const messagePrefix = statusCode ? `${statusCode} Error - ` : '';
  const messageBody = error?.message ?? '';
  const message = `${messagePrefix}${messageBody}`;

  ExceptionLogger.error(message, error);

  if (statusCode && statusCode === 404) {
    return <Error404 />;
  }

  return (
    <NextError
      statusCode={statusCode || defaultErrorCode}
      error={error}
    />
  );
};

Error.getInitialProps = ({ res, err }: any) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null;

  // We're only checking the res.statusCode here, because the statusCode in the
  // err object would be a result of an API request, and likely indicates our user
  // isn't logged in. We don't want to cache the error page for that case so that
  // it renders correctly once they do login. A 404 on res.statusCode is the result
  // of asking for a page that doesn't exist, so we can safely cache that.
  if (res.statusCode < 500) {
    res?.setHeader('Cache-Control', 'private, max-age=600');
  }

  return { statusCode, error: err };
};

export default Error;
