import * as React from 'react';

import { ErrorPage } from '@circleci/compass';
import getConfig from 'next/config';
import Router from 'next/router';

export const Error404 = ({
  onGoBack = () => {
    return Router.back();
  },
}: {
  onGoBack?: () => void;
}) => {
  const {
    publicRuntimeConfig: { isEnterprise },
  } = getConfig();
  return (
    <ErrorPage
      onActionClick={onGoBack}
      isEnterprise={isEnterprise}
      fgImageUrl='/error-page/background-images/404-door.png'
      bgImageUrl='/error-page/background-images/stars.jpg'
    />
  );
};
