import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useOrgSlug } from '~/hooks/usePersistedOrgSlug';

import composeRedirectUrl from './composeRedirectUrl';

export const PipelinesOrProjects = () => {
  const orgSlug = useOrgSlug();
  const { query, pathname } = useRouter();

  useEffect(() => {
    const redirectUrl = composeRedirectUrl(orgSlug, pathname);
    window.location.replace(redirectUrl);
  }, [orgSlug, pathname, query]);

  return null;
};
