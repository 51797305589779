import * as React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';

import { MeContextProvider } from '~/contexts/MeContext';
import { PipelinesOrProjects } from '~/utils/redirect';

const Page = (): JSX.Element => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider
      client={queryClient}
      contextSharing={true}
    >
      <MeContextProvider>
        <PipelinesOrProjects />
      </MeContextProvider>
    </QueryClientProvider>
  );
};

Page.getInitialProps = async ({ res }: any) => {
  // Note: cache header allows the CDN to cache server responses. This is
  // safe because this page doesn't currently do any server side rendering
  // other than embedding runtime configuration variables from the environment.
  // This cache header will need to change if any user-specific SSR is enabled,
  // or else we might serve up one user's page to another user.
  res?.setHeader('Cache-Control', 'private, max-age=600');

  return {};
};

export default Page;
